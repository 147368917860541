.customerreghead{
    font-size: 30px;
}
.login-form-register{
  max-width: 600px;
}

.label-heading{
  /* text-align: left!important; */
  display: flex;
}

@media only screen and (max-width: 468px) and (min-width: 360px) {
  .customerreghead {
      font-size: 18px!important;
  }
}