.gfgfooter{
    color: white;
    text-decoration: none;
    letter-spacing: 2px;
}
.gfgfooter:hover{
    color: white;
}
.linksClass{
    text-decoration: none !important;
    color: #000 !important;
}

.footer-heading{
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 5px;
}

.footer-ul .footer-menu-item{
    text-align: left;
    list-style-type: none;
}