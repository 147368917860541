.login-page{
    margin-top: 50px;
}
.section-heading{
    text-transform: uppercase;
    letter-spacing: 2px;
}
.label-heading{
    /* text-align: left!important; */
    display: flex;
}