.merchantProdsTable{
    overflow-y: scroll;
}
.productEditSpecHeading{
    text-align: start !important;
}
.productEditSpecul{
    text-align: center !important;
}
.productSpecificationLiEdit{
    list-style: none;
    /* text-align: center; */
}
.editPriceLable{
    margin-right: 10px !important;
}
.merchantproductsaccordbody{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.editPriceInput {
    border: none;
    border-bottom: 1px solid transparent; /* Initial state without border */
    color: black;
    font-weight: 600;
    outline: none;
}

.editPriceInput:focus-visible,
.editPriceInput:focus-within,
.editPriceInput:visited,
.editPriceInput:focus,
.editPriceInput:target,
.editPriceInput:hover {
    border: none;
    border-bottom: 1px solid black; /* Border at the bottom when focused */
    color: black;
    font-weight: 600;
    outline: none;
}

.merchantprodsBreadcrumb{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.merchantprodedit{
    margin-right: 5px;
}

.productSpecificationLi{
    list-style-type: none;
}

.price-edit{
    display: flex;
    align-items: baseline;
    justify-content: center;
}
.addProductsClass:hover{
    background-color: #e65f3a;
}

.merchantProductsHeading {
    margin-top: 40px;
    letter-spacing: 2px !important;
    margin-bottom: 40px;
  }
  
  .merchantProductsHeading span {
    color: #e65f3a;
  }
  
  .merchantProductsHeading::after {
    content: "";
    border-top: 3px solid #063263;
    display: block;
    width: 2em;
    margin: auto;
  }
.productpriceInput{
    margin-left: 5px !important;
}
.selectedProductName{
    text-transform: capitalize;
}
.dropdownButton{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.merchantProductsTableClass{
    margin-bottom: 30px;
}