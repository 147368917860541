.mapContainer {
  position: relative; /* Ensure child elements are positioned relative to this */
  width: 100%;
  height: 400px;
}

.pac-container {
  z-index: 1060; /* Ensures it’s above other elements */
  position: absolute !important; /* Relative to the input field */
  /* width: 100% !important; Make the suggestion box take full width of the input */
  max-width: none !important; /* Ensure width constraints are removed */
}

.locationInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  z-index: 1000; /* Ensure it’s above map elements */
}

@media only screen and (max-width: 468px) and (min-width: 360px) {
  .pac-container {
    width: 80% !important; /* Adjust width for mobile devices */
  }
}
