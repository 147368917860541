.headerClass{
    background-color: #063263;
}
.gfg_logo_class{
    width: 120px;
}
.profileAnchor{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    /* border: 1px solid #00234D; */
    padding: 10px;
    /* border-bottom-right-radius: 10px; */
    /* background-color: #00234D; */
    color: white;
}
.profileAnchor:hover{
    color: aliceblue;
}
.profileIconText{
    margin-bottom: 0px !important;
    margin-right: 5px !important;
    color: #fff!important;
}
.profileSvgIcon{
    color: white;
}
.profileSvgIcon:hover{
    color: #e65f3a;
}
.logo-main{
    color: #000;
    text-decoration: none;
    font-size: 36px;
    letter-spacing: 3px;
    font-weight: 600;
    font-family: mulish;
}
.logo-main:hover{
    color: #000;
}
.linksClass{
    text-decoration: none !important;
    color: #000 !important;
}
.headersearch{
    width: 70% !important;
}
.headersearchsection{
    text-align: -webkit-right;
    position: relative;
    /* width: 600px; */
}
.headersearchicon{
    position: absolute;
    right: 4%;
    top: 30%;
}

.headerloginclass{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
    padding: 8px;
}
.headerloginclass:hover{
    color: #e65f3a;
}
.headerservicesclass{
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
    text-align: center;
}
.headerservicesclass:hover{
    color: #e65f3a;
}
.headerservicesclass:active{
    color: #e65f3a;
}
.headerlogout_button{
    /* padding: 8px 3px 8px 8px; */
    /* border-top-left-radius: 10px; */
    background-color: transparent;
    color: white;
}
.headerlogout_button:hover{
    color: #e65f3a;
}
.sticky-header{
    padding: 15px 0px!important;
}

@media only screen and (max-width: 468px) and (min-width: 360px) {
    .headerClass{
        padding: 0px!important;
    }
    .headerservicesclass:hover{
        color: #e65f3a;
    }
    .header-marg{
        padding: 10px 0px!important;
        background-color: #063263 !important;
    }
    .navbarToggleIcon{
        background-color: #fff !important;
    }
    .headersearchsection{
        margin-top: 20px;
        text-align: -webkit-left;
    }
    .headersearch{
        width: 65% !important;
    }
    .headersearchicon{
        right: 43%;
    }
    .headerloginclass{
        padding: 10px;
    }
    .headerloginclass:hover{
        color: #e65f3a;
    }
    .headerlogout_button{
        padding: 8px 3px 8px 0px !important;
    }
    .headerlogout_button:hover{
        color: #e65f3a;
    }
    .profileAnchor{
        font-size: 20px;
        justify-content: start;
    }
  }